import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

//components
import withLocalization from '~/hoc/withLocalization';
import applicationRouter from '~/hoc/applicationRouter';

//elements
import Button from '../../../components/CustomButton/CustomButton.jsx';
import SwitchWidget from '../../Admin/ControlPanel/SwitchWidget.js'

//assets
import CloseIcon from "~/assets/img/modal-close-icon.svg";
import { permissions } from '../../../utils/constants.js';
import { checkCircleSVG, crossCircleSVG, saveSVG, editSVG, backSVG } from '../../../utils/constants';

class UserPermissions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            modulePermissions: [],
            currentId: null,
            currentPermission: null,
            userDetails: null
        }
    }

    componentDidMount() {
        const { userId } = this.props;
        if (!userId) return;
        this.setState({ loading: true })
        this.loadData();
    }

    async loadData() {
        const { userStore, userId, commonStore, t } = this.props
        try {
            const { userPermissions, userDetails } = await userStore.getUserPermissions(userId);
            const _modulePermissions = [];
            userPermissions && userPermissions.length && userPermissions.forEach(ele => {
                _modulePermissions.push({
                    module: ele.module,
                    permissions: ele.permissions
                })
            });
            this.setState({ modulePermissions: _modulePermissions, userDetails, loading: false, currentId: null, currentPermission: null })
        }
        catch (err) {
            commonStore.addNotification(t('Something went wrong'), null, 'error');
            this.setState({ loading: false, currentId: null, currentPermission: null });
        }

    }

    handlePermissonChange = (permissionName, value) => {
        const { currentPermission } = this.state;
        if (!currentPermission) return;
        currentPermission.permissions[permissionName] = value;
        this.setState({ currentPermission })
    }

    handleEdit = (module, permissions) => {
        const { userId } = this.props;
        const _currrentPermission = {
            user_id: userId,
            module_name: module,
            permissions: { ...permissions }
        }
        this.setState({ currentId: module, currentPermission: _currrentPermission })
    }

    handleSave = () => {
        this.setState({ loading: true }, this.updateUserPermissions);
    }

    updateUserPermissions = async () => {
        const { userStore, commonStore, t } = this.props;
        const { currentPermission } = this.state;
        try {
            await userStore.setUserPermissions(currentPermission);
            this.loadData();
        }
        catch (err) {
            commonStore.addNotification(t('Something went wrong'), null, 'error');
            this.setState({ loading: false });
        }
    }

    resetCurrentPermission = () => {
        this.setState({ currentPermission: null, currentId: null });
    }

    componentWillUnmount = () => {
        this.resetCurrentPermission();
    }

    renderModalBody = () => {
        const { userId, t } = this.props;
        const { loading, modulePermissions, currentId, currentPermission } = this.state;
        if (!userId) return <div>User Id not found</div>
        if (loading) return <div>{t('Hold On, This Will take a moment!')}</div>
        return <div className="table-responsive">
            <table className='table'>
                <thead>
                    <tr>
                        <th>{t('Module')}</th>
                        {permissions.map((ele) => {
                            return <th key={ele}>{t(ele.label)}
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip-top">
                                            {t(ele.description)}.
                                        </Tooltip>
                                    }
                                >
                                    <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                </OverlayTrigger></th>
                        })}
                        <th>{t('Actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {modulePermissions && modulePermissions.length ? modulePermissions.map((ele, index) => (
                        <tr key={`md_0_${index}`} className='text-center'>
                            <td>{t(ele.module)}</td>
                            {permissions.map((e, index) => (
                                <th key={`permi_0_${index}`} style={{ borderBottom: '0' }}>

                                    {currentId && currentId === ele.module ?
                                        <SwitchWidget
                                            value={currentPermission.permissions[e.value]}
                                            onChange={checked => this.handlePermissonChange(e.value, checked)} />
                                        :
                                        ele.permissions[e.value] ? checkCircleSVG({ width: 16, fillColor: '#16C51D' }) : crossCircleSVG({ width: 18, fillColor: '#FF0000' })}
                                </th>
                            ))}
                            <th style={{ borderBottom: '0' }}>
                                {currentId && currentId === ele.module ?
                                    <>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="tooltip-top">{this.props.t('Update Permissions')}</Tooltip>}
                                        >
                                            <Button icon_sm fill
                                                onClick={this.handleSave}>
                                                {saveSVG({ width: 20, fillColor: '#2550AC' })}
                                            </Button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="tooltip-top">{this.props.t('Cancel')}</Tooltip>}
                                        >
                                            <Button icon_sm fill ms2
                                                onClick={this.resetCurrentPermission}>
                                                {backSVG({ width: 20, fillColor: '#2550AC' })}
                                            </Button>
                                        </OverlayTrigger>
                                    </>
                                    :
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="tooltip-top">{this.props.t('Change Permissions')}</Tooltip>}
                                    >
                                        <Button icon_sm fill
                                            disabled={!!currentId}
                                            onClick={() => this.handleEdit(ele.module, ele.permissions)}>
                                            {editSVG({ width: 20, fillColor: '#2550AC' })}
                                        </Button>
                                    </OverlayTrigger>
                                }
                            </th>
                        </tr>
                    ))
                        :
                        <tr className='text-center'>
                            <td colSpan={6}>
                                <span className='font-size-15 fw-bold text-muted'>{t('No data found')}</span>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div >
    }

    render() {
        const { t, userId, handleModalState } = this.props;
        const { userDetails } = this.state;
        return (
            <Modal
                size="lg"
                className="modal fade"
                show={!!userId}
                onHide={() => handleModalState('showUserPermissionModal', false, false)}
            >
                <Modal.Header>
                    <Modal.Title>{t('Manage User Permissions')}</Modal.Title>
                    <div className='modal-header__revised__closebtn' onClick={() => handleModalState('showUserPermissionModal', false, false)}>
                        <img
                            src={CloseIcon}
                            className="cursor-pointer"
                            alt='close_button'
                        />
                    </div>
                </Modal.Header>
                <Modal.Body className='mb-2'>
                    <div className='d-flex justify-content-space-between px-4'>
                        <span className='font-size-12'>{t('User')}:&nbsp;<span className='font-size-15 fw-bold'>{userDetails?.fullname}</span></span>
                        <span className='font-size-12'>{t('User type')}:&nbsp;<span className='font-size-15 fw-bold'>{(userDetails?.userType || '').toUpperCase()}</span></span>
                    </div>
                    {this.renderModalBody()}
                </Modal.Body>
            </Modal>
        );
    }
}
export default inject('userStore', 'commonStore')(applicationRouter(withLocalization(observer(UserPermissions))));
