import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

//components
import withRemoveDialog from '~/hoc/withRemoveDialog';
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from '~/hoc/withLocalization';
import GenericList from '~/components/GenericList/GenericList';
import GenericFilter from '../../../components/GenericList/GenericFilter.js';
import ModalEditor from '../Timelogs/ModalAccept';
import VacationsTotals from './VacationsTotals';
import UserProfileLogo from '../../../components/UserProfileLogo/index.js';

//elements
import Button from '../../../components/CustomButton/CustomButton.jsx';

//utils
import { statusMap } from '~/utils/normalStatuses';
import { serverTimeFormat } from '~/library/core';
import { shortDateFormat } from '~/utils/dateFormat';
import { absence_and_vacation_statuses } from '~/utils/normalStatuses';
import customerFeature from '~/utils/customerFeature';
import { getIntegrationIconWithStatus } from '~/utils/integrationUtils';

//assets
import Edit from '../../../assets/img/editing.svg';
import Delete from '../../../assets/img/deleting.svg';
import CloseModalIcon from '../../../assets/img/CloseApproveModalIcon.svg';
class VacationsList extends Component {
    constructor(props) {
        super(props);
        const { page, isEdit } = this.props.router.location.state || {};
        this.state = {
            accepting: null,
            acceptanceChanged: false,
            selected: null,
            page: page ? page : 0,
            isPageAddedOrEdited: isEdit ? isEdit : false
        };
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    handleTableButtonAction = (id, type) => {
        if (type === 'edit') this.props.router.navigate(`/admin/vacations/${id}`,
            { state: { vacation: id, page: this.state.page, isEdit: this.state.isPageAddedOrEdited } });
        if (type === 'remove') {
            return this.props.handleDelete(id, (id) => {
                return this.props.vacationStore.remove(id).then((res) => {
                    this.props.vacationStore.resetLastListLoadTime(new Date());
                    return res;
                });
            });
        }
    };

    componentWillUnmount() {
        this.props.vacationStore.resetLastListLoadTime(null);
    }

    renderTableActionButtons = (id, full) => {
        const { commonStore, userStore, t } = this.props;
        const { config } = commonStore;
        const { user_type } = userStore.currentUser;
        const showStatus = (user_type === 'pm' || user_type === 'admin') && customerFeature('allow_accepting');
        const allow = (user_type === 'pm' || user_type === 'admin') && customerFeature('pm_allow_editing_all');

        let statusText = full.status;
        if (!statusText) statusText = 'active';
        statusText = statusMap[statusText] || '';
        return (
            <div className="actions-center">
                {getIntegrationIconWithStatus(config, full, t)}
                {customerFeature('allow_accepting') && (
                    <div
                        className={`status-block status-${full.status || 'active'}${user_type === 'member' ? ' status-no-click-for-member' : ''
                            }`}
                        onClick={() => (showStatus ? this.handleAccept(id, full) : null)}
                    >
                        {full.status.toLowerCase() === 'active' ||
                            !(full.status_changed_by && full.status_changed_on) ? (
                            <div className="text-capitalize">{t(statusText)}</div>
                        ) : (
                            <>
                                <div className="text-capitalize">
                                    {full.status_changed_by &&
                                        moment(new Date(full.status_changed_on)).format(serverTimeFormat(true))}
                                </div>
                                <div className="text-capitalize">
                                    {commonStore.getUsername(full.status_changed_by)}
                                </div>
                            </>
                        )}
                    </div>
                )}
                <OverlayTrigger
                    key="vaction_edit"
                    placement="top"
                    overlay={
                        <Tooltip id="tooltip-top">
                            {t(full.is_editable ? 'Edit Vacation' : 'Cannot manage this vacation')}
                        </Tooltip>
                    }
                >
                    <Button icon_sm fill
                        onClick={() => this.handleTableButtonAction(id, 'edit')}
                        disabled={!full.is_editable}>
                        <img src={Edit} alt={"edit button"} />
                    </Button>
                </OverlayTrigger>

                {allow &&
                    <OverlayTrigger
                        key="vaction_delete"
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip-top">
                                {t(full.is_editable ? 'Delete Vacation' : 'Cannot delete this vacation')}
                            </Tooltip>
                        }
                    >
                        <Button icon_sm_delete fill
                            onClick={() => this.handleTableButtonAction(id, 'remove')}
                            disabled={!full.is_editable}>
                            <img src={Delete} alt="delete button" />
                        </Button>
                    </OverlayTrigger>
                }
            </div>
        );
    };

    handleAccept(id, full) {
        if (full.status === 'approved') return;
        this.setState({
            accepting: {
                ids: [id],
                count: 1,
                entity: 'vacation',
            },
        });
    }

    onSelectionChanged(ids) {
        this.setState({ selected: ids });
    }

    onMassAccept() {
        const ids = [];
        if (!this.state.selected) return;
        Object.keys(this.state.selected).forEach((id) => {
            if (this.state.selected[id]) ids.push(id);
        });
        this.setState({
            acceptanceChanged: false,
            accepting: {
                ids,
                count: ids.length,
                entity: 'vacation',
            },
        });
    }

    afterMassAccept() {
        this.props.vacationStore.resetLastListLoadTime(new Date());
        this.setState({ accepting: null, acceptanceChanged: true });
    }

    //handles generic list page change//
    handlePageChange = (page, isEdit) => {
        this.setState({ page, isPageAddedOrEdited: isEdit });
    };

    render() {
        const { loading, filters, appliedFilters } = this.props.vacationStore;
        const { user_type, id } = this.props.userStore.currentUser;
        const { accepting, page, isPageAddedOrEdited } = this.state;
        const showStatus = user_type === 'pm' && customerFeature('allow_accepting');
        const { config } = this.props.commonStore;
        const dateTimeRules =
            config.client && config.client.data && config.client.data.dateTimeRules
                ? config.client.data.dateTimeRules
                : false;
        return (
            <Fragment>
                <Modal size="lg" show={!!accepting} onHide={() => this.setState({ accepting: null })}>
                    <Modal.Header>
                        <Modal.Title className="w-100 text-center m-0">{this.props.t('Accept/reject')}</Modal.Title>
                        <img
                            src={CloseModalIcon}
                            alt='closeIcon'
                            className="cursor-pointer"
                            onClick={() => this.setState({ accepting: null })}
                        />
                    </Modal.Header>

                    <Modal.Body>
                        <ModalEditor params={accepting} afterSave={() => this.afterMassAccept()} />
                    </Modal.Body>
                </Modal>
                {(user_type === 'member' || user_type === 'pm') && <VacationsTotals user={id} />}
                <div className="table-list">
                    <div className="table-list__header"></div>
                    <GenericList
                        columns={[
                            user_type === 'member'
                                ? null
                                : {
                                    Header: '',
                                    id: 'user_image',
                                    accessor: ({ user_image }) => (
                                        <UserProfileLogo image_id={user_image ? user_image : false} />
                                    ),
                                    maxWidth: 60,
                                },
                            user_type === 'member'
                                ? null
                                : {
                                    Header: 'Member',
                                    id: 'first_name',
                                    isSortable: true,
                                    accessor: ({ first_name, last_name }) => (
                                        <span>{first_name + ' ' + last_name}</span>
                                    ),
                                    textAlign: 'start',
                                },
                            {
                                Header: 'Project',
                                id: 'project_name',
                                isSortable: true,
                                accessor: ({ project_number, project_name }) => <span>
                                    {project_name ? ((project_number ? `${project_number} | ` : '') + project_name) : "-"}
                                </span>,
                            },
                            {
                                Header: 'type',
                                id: 'vacation_type',
                                accessor: ({ vacation_type }) => (
                                    <span className="text-capitalize">{vacation_type}</span>
                                ),
                            },
                            {
                                Header: 'From date',
                                id: 'from',
                                isSortable: true,
                                maxWidth: 90,
                                accessor: ({ from }) => <span>{shortDateFormat(from, dateTimeRules)}</span>,
                            },
                            {
                                Header: 'To date',
                                id: 'to',
                                isSortable: true,
                                maxWidth: 90,
                                accessor: ({ to }) => <span>{shortDateFormat(to, dateTimeRules)}</span>,
                            },
                            {
                                Header: 'Total days',
                                id: 'total_days',
                                maxWidth: 90,
                                accessor: ({ total_days }) => <span>{total_days}</span>,
                            },
                            {
                                Header: 'Actions',
                                id: 'actions',
                                sortable: false,
                                maxWidth: 150,
                                minWidth: 100,
                                accessor: (full) => this.renderTableActionButtons(full.id, full),
                            },
                        ]}
                        header={
                            <GenericFilter
                                filters={filters}
                                setFilter={(name, value) => this.props.vacationStore.setFilter(name, value)}
                                onFilter={() => this.props.vacationStore.onFilter()}
                                loading={loading}
                                onChangeStatus={() => this.onMassAccept()}
                                showStatus={showStatus}
                                hasDateFilters={true}
                                hasStatusFilter={true}
                                statusOptions={absence_and_vacation_statuses}
                            />
                        }
                        tableHeader={this.props.t('Vacations')}
                        filters={appliedFilters}
                        cutomCheckBoxClass={"top-position"}
                        onSelectionChanged={(selection) => this.onSelectionChanged(selection)}
                        allowCheckboxes={showStatus}
                        lastListLoadTime={this.props.vacationStore.lastListLoadTime}
                        requestData={(params) => this.props.vacationStore.loadList(params)}
                        handlePageChange={(page, isEdit) => this.handlePageChange(page, isEdit)}
                        page={page}
                        isPageAddedOrEdited={isPageAddedOrEdited}
                    >
                        <Button fill wd onClick={() => this.props.onAdd(page, isPageAddedOrEdited)}>
                            <i className="fa fa-plus-circle fa-xl margin-right-10" />
                            {this.props.t('Add new')}
                        </Button>
                    </GenericList>
                </div>
            </Fragment>
        );
    }
}

export default inject(
    'vacationStore',
    'userStore',
    'timelogStore',
    'commonStore'
)(applicationRouter(withLocalization(withRemoveDialog(observer(VacationsList)))));
