import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { v4 as uuid } from 'uuid';

//components
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from '~/hoc/withLocalization';

//elements
import CustomMultiSelect from '../../../elements/CustomMultiSelect';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import LoadingSpinner from '~/elements/LoadingSpinner';
import { SimpleSelectWidget } from '../../../components/GenericForm/Widgets/SimpleSelect.js';

//assets
import Delete from '../../../assets/img/deleting.svg';

class ManagesMembersForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            availablePmMembers: [],
            projectForFilterOptions: [],
            isLoading: false,
            selectedPmMembers: [],
            selectedProject: { label: 'All', value: 'all', projectMembers: [] },
        };
        this.loadData = this.loadData.bind(this);
        this.onSave = this.onSave.bind(this);
        this.handleMemberSelect = this.handleMemberSelect.bind(this);
    }

    componentDidMount() {
        this.setState({ isLoading: true }, this.loadData)
    }

    componentDidUpdate(previousProps) {
        const { id } = this.props;
        if (id !== previousProps.id) {
            this.setState({ isLoading: true }, this.loadData);
        }
    }

    async loadData() {
        const { id, userStore, commonStore } = this.props;
        if (!id) return;
        try {
            const { projectForFilter, availablePmMembers, assignedPmMembers } = await userStore.getPmMembersToAssign(id);
            const disabledOptions = assignedPmMembers.filter(e => !e.is_assigned_directly).map(e => e.id);
            const _availablePmMembers = availablePmMembers.map(e => ({
                value: e.id,
                label: `${e.first_name} ${e.last_name}`,
                disabled: disabledOptions.includes(e.id) || e.id === id
            }));
            const selectedPmMembers = _availablePmMembers.filter(e => assignedPmMembers.some(ele => ele.id === e.value) || e.value === id);
            const projectForFilterOptions = projectForFilter.map(e => ({ label: e.number ? `${e.number} | ${e.name}` : e.name, value: e.id, projectMembers: e.project_members }));
            projectForFilterOptions.unshift({ label: 'All', value: 'all', projectMembers: [] });
            this.setState({
                projectForFilterOptions,
                availablePmMembers: _availablePmMembers,
                selectedPmMembers,
                isLoading: false
            });

        } catch (err) {
            commonStore.addNotification(err, null, 'error');
            this.setState({ isLoading: false });
        }
    }

    async onSave() {
        const { userStore, commonStore, afterSave, id, t } = this.props;
        try {
            this.setState({ isLoading: true });
            await userStore.assignMembersToPm(id, {
                ManagesUsers: this.state.selectedPmMembers.map(e => e.value)
            });
            commonStore.addNotification(t('Saved'), null, 'success');
            afterSave();
        } catch (err) {
            commonStore.addNotification(err, null, 'error');
        } finally {
            this.setState({ isLoading: false });
        }
    }

    handleMemberSelect = (selected) => {
        const { availablePmMembers } = this.state;
        const selectedPmMembers = selected && selected.length ?
            availablePmMembers.filter(ele => selected.includes(ele.value))
            : availablePmMembers.filter(ele => ele.disabled);
        this.setState({ selectedPmMembers });
    };


    render() {
        const { availablePmMembers, projectForFilterOptions, selectedPmMembers, selectedProject, isLoading } = this.state;
        const { id, t } = this.props;
        if (isLoading) return <LoadingSpinner />;
        const memberOptions = selectedProject && selectedProject.value !== 'all' ?
            availablePmMembers.filter(ele => selectedProject.projectMembers.includes(ele.value))
            : availablePmMembers;
        memberOptions.sort((a, b) => a.label - b.label);
        selectedPmMembers.sort((a, b) => a.label - b.label);
        return (
            <>
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="form-group field field-string">
                            <label className="control-label d-flex justify-content-space-between">
                                {t('Select Members')}
                                <span className='text-danger font-size-10 fw-bold'>
                                    {t('Available members')}:&nbsp;{memberOptions.length}
                                </span>
                            </label>
                            <CustomMultiSelect
                                options={memberOptions}
                                value={selectedPmMembers.length ? selectedPmMembers.map(e => e.value) : []}
                                onChange={this.handleMemberSelect}
                            />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="form-group field field-string">
                            <label className="control-label mt-2">{t('Filter members by project')}</label>
                            <SimpleSelectWidget
                                options={projectForFilterOptions}
                                value={selectedProject}
                                onChange={selected => this.setState({ selectedProject: selected })}
                                styleClasses={"custom-select-max-height-200"}
                            />
                        </div>
                    </div>
                    <div className="col-md-2 text-end mt-3">
                        <Button primary fill onClick={() => this.onSave()}>
                            {t('Save')}
                        </Button>
                    </div>
                </div>
                <div style={{ overflowY: 'scroll', maxHeight: '500px' }}>
                    <table className="table table-hover project-members-form">
                        <thead>
                            <tr className="table-container-header">
                                <th className='text-start'>{t('Member')}</th>
                                <th className='text-start'>{t('Assigned Type')}</th>
                                <th className='text-right'>{t('Action')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedPmMembers.length ? selectedPmMembers.map((member) => (
                                <tr key={uuid()}>
                                    <td className='text-start'>{member.label}</td>
                                    <td className='text-start'>{t(!member.disabled ? 'Directly' : id === member.value ? 'Self' : 'Project')}</td>
                                    <td className="text-right">
                                        <Button
                                            icon_sm_delete
                                            disabled={member.disabled}
                                            title={t(!member.disabled ? 'Remove emoloyee' : id === member.value ? 'Self' : 'Employee is managed from project')}
                                            onClick={() => this.handleMemberSelect(selectedPmMembers.filter(e => e.value !== member.value).map(e => e.value))}
                                        >
                                            <img src={Delete} alt="delete button" />
                                        </Button>
                                    </td>
                                </tr>
                            )) :
                                <tr>
                                    <td colSpan={3} className="text-center">{t('No members selected')}</td>
                                </tr>}
                        </tbody>
                    </table>
                </div>
            </>
        );
    }
}

export default inject(
    'commonStore',
    'userStore'
)(applicationRouter(withLocalization(observer(ManagesMembersForm))));
